import { call, put } from "redux-saga/effects";

import * as actions from "../actions";
import { usersApi as api } from "..";
import { tokenService, organizationService } from "../../../services";

export const users = {
  * get() {
    try {
      const response = yield call(api.list);
      const payload = response ? response.data : {};

      yield put(actions.users.get.success(payload));
    } catch (error) {
      yield put(actions.users.get.error(error));
      throw error;
    }
  },

  * self() {
    try {
      const response = yield call(api.self);
      const payload = response ? response.data : {};

      yield put(actions.users.self.success(payload));
    } catch (error) {
      yield put(actions.users.self.error(error));
      throw error;
    }
  },

  * post(action) {
    try {
      const response = yield call(api.post, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.users.post.success(payload));
    } catch (error) {
      yield put(actions.users.post.error(error.response && error.response.data));
    }
  },

  * patch(action) {
    try {
      const response = yield call(api.patch, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.users.patch.success(payload));
    } catch (error) {
      yield put(actions.users.patch.error(error));
      throw error;
    }
  },

  * moveUser(action) {
    try {
      const response = yield call(api.moveUser, action.payload);
      const payload = response ? response.data : {};

      yield put(actions.users.patch.success(payload));
    } catch (error) {
      yield put(actions.users.patch.error(error));
      throw error;
    }
  },

  * destroy(action) {
    try {
      const { userId } = action.payload;
      const response = yield call(api.destroy, { userId });
      const payload = response ? response.data : {};

      yield put(actions.users.destroy.success(payload));
    } catch (error) {
      yield put(actions.users.destroy.error(error));
      throw error;
    }
  },

  * bulkDelete(action) {
    try {
      const { ids } = action.payload;
      const response = yield call(api.bulkDelete, { ids });
      const payload = response ? response.data : {};

      yield put(actions.users.destroy.success(payload));
    } catch (error) {
      yield put(actions.users.destroy.error(error));
      throw error;
    }
  },
};

export const invite = {
  * accept(action) {
    try {
      const { organizationUserId, code, password = null } = action.payload;
      const response = yield call(api.acceptInvite, { organizationUserId, code, password });

      // Store the login JWT token and set the organization
      const { token } = response.data;
      const { organizationId } = response.data.organizationUser;
      tokenService.set(token);
      organizationService.setCurrent(organizationId);

      yield put(actions.invite.accept.success(response.data));
    } catch (error) {
      yield put(actions.invite.accept.error(error));
      throw error;
    }
  },
  * metaData(action) {
    try {
      const response = yield call(api.inviteMetaData, action.payload);
      yield put(actions.invite.metaData.post.success(response.data));
    } catch (error) {
      yield put(actions.invite.metaData.post.error(error));
      throw error;
    }
  },
};

export const password = {
  * init(action) {
    try {
      const { userId } = action.payload;
      yield call(api.initPasswordChange, { userId });
      yield put(actions.password.change.init.success());
    } catch (error) {
      yield put(actions.password.change.init.error(error));
      throw error;
    }
  },

  * post(action) {
    try {
      const { token, userId, newPassword, newPasswordConfirmation } = action.payload;
      yield call(api.postPasswordChange, {
        token,
        userId,
        newPassword,
        newPasswordConfirmation,
      });
      yield put(actions.password.change.post.success());
    } catch (error) {
      yield put(actions.password.change.post.error(error));
      throw error;
    }
  },
};

export const userAttributes = {
  * patch(action) {
    const {
      onboardingComplete = null,
      instructionsRead = [],
      disableGameInstructions = null,
    } = action.payload;

    const response = yield call(api.userAttributes.patch, {
      onboardingComplete,
      instructionsRead,
      disableGameInstructions,
    });

    yield put(actions.users.userAttributes.patch.success(response.data));
  },
};
