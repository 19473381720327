import { get } from "lodash";
import { backendService, organizationService } from "../../../services";
import { utils } from "../../shared";

export const list = async () => {
  const url = `/dashboard/users`;

  const organizationId = organizationService.getCurrent();
  const response = await backendService.get({
    url,
    data: { organization_id: organizationId },
  });
  return { data: utils.fromSnakeToCamel(response.data.users) };
};

export const self = async () => {
  const url = `/dashboard/users/self`;

  const response = await backendService.get({ url });
  return { data: utils.fromSnakeToCamel(response.data.self) };
};

export const post = async (payload) => {
  const { email, role } = payload;
  const consumeProduct = get(payload, "consumeProduct", null);
  const url = `/dashboard/users`;

  const organizationId = payload.organizationId || organizationService.getCurrent();

  const response = await backendService.post({
    url,
    data: {
      email,
      role,
      organization_id: organizationId,
      consume_product: consumeProduct ? {
        organization_product_purchase_id: consumeProduct.organizationProductPurchaseId,
      } : null,
    },
  });

  return { data: utils.fromSnakeToCamel(response.data.users) };
};

export const moveUser = async ({ id, newOrganizationId }) => {
  const url = `/dashboard/users/${id}/move`;

  const response = await backendService.patch({
    url,
    data: { new_organization_id: newOrganizationId },
  });

  return { data: utils.fromSnakeToCamel(response.data.users) };
};

export const patch = async ({ id, role }) => {
  const url = `/dashboard/users/${id}`;

  const response = await backendService.patch({ url, data: { role } });
  return { data: utils.fromSnakeToCamel(response.data.users) };
};

export const destroy = async ({ userId }) => {
  const url = `/dashboard/users/${userId}`;

  const response = await backendService.destroy({ url });
  return { data: utils.fromSnakeToCamel(response.data.users) };
};

export const bulkDelete = async ({ ids }) => {
  const url = `/dashboard/users/bulk-delete`;

  const response = await backendService.post({ url, data: { ids } });
  return { data: utils.fromSnakeToCamel(response.data.users) };
};

export const acceptInvite = async ({ organizationUserId, code, password }) => {
  const url = `/dashboard/users/accept-invite`;

  let data = {
    organization_user_id: organizationUserId,
    code,
  };

  if (password) {
    data = {
      ...data,
      new_password: password.password,
      new_password_confirmation: password.passwordConfirm,
    }
  }

  const response = await backendService.post({
    url,
    data,
  });

  return { data: utils.fromSnakeToCamel(response.data) };
};

export const inviteMetaData = async ({ organizationUserId, code }) => {
  const url = `/dashboard/users/invite-meta-data`;

  const response = await backendService.post({ url, data: {
      organization_user_id: organizationUserId, code,
    },
  });
  return { data: utils.fromSnakeToCamel(response.data) };
}

export const initPasswordChange = async ({ userId }) => {
  const url = `/authentication/login/init-reset-password`;
  const response = await backendService.post({ url, data: { user_id: userId } });
  return { data: utils.fromSnakeToCamel(response.data) };
};

export const postPasswordChange = async ({ userId, token, newPassword, newPasswordConfirmation }) => {
  const url = `/authentication/login/reset-password`;
  const response = await backendService.post({
    url,
    data: {
      user_id: userId,
      token,
      new_password: newPassword,
      new_password_confirmation: newPasswordConfirmation,
    },
  });
  return { data: utils.fromSnakeToCamel(response.data) };
};

export const userAttributes = {
  get: async () => {
    const url = `/dashboard/user-attributes`;
    const response = await backendService.get({ url });
    return { data: utils.fromSnakeToCamel(response.data) };
  },
  patch: async ({
    onboardingComplete = null,
    instructionsRead = [],
    disableGameInstructions = null,
  }) => {
    const url = `/dashboard/user-attributes`;
    const response = await backendService.patch({
      url,
      data: {
        onboarding_complete: onboardingComplete,
        instructions_read: instructionsRead,
        disable_game_instructions: disableGameInstructions,
      },
    });

    return { data: utils.fromSnakeToCamel(response.data) };
  },
};
